import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import App from './App';
import Dashboard from './components/pages/Dashboard';
import Equipment from './components/pages/Equipment';
import EquipmentNew from './components/pages/EquipmentNew';
import EquipmentEdit from './components/pages/EquipmentEdit';
import Department from './components/pages/Department';
import DepartmentEdit from './components/pages/DepartmentEdit';
import { PrivateRoute } from './components/PrivateRoute';

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path='/' component={App} />
      <PrivateRoute exact path='/dashboard' component={Dashboard} />
      <PrivateRoute exact path='/equipments' component={Equipment} />
      <PrivateRoute exact path='/equipment/new' component={EquipmentNew} />
      <PrivateRoute exact path='/equipment/edit' component={EquipmentEdit} />
      <PrivateRoute exact path='/department' component={Department} />
      <PrivateRoute exact path='/department/edit' component={DepartmentEdit} />
    </Switch>
  </BrowserRouter>
);

export default Router;
