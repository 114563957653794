import HeaderUI from '../HeaderUI';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import React, { useState, useEffect, useRef } from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import firebaseConfig from '../firebase/FirebaseConfig';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const BootstrapButton = withStyles({
  root: {
    backgroundColor: 'rgb(0, 156, 222)',
    borderColor: 'rgb(0, 156, 222)',
    '&:hover': {
      backgroundColor: 'rgb(0, 156, 222)',
      borderColor: 'rgb(0, 156, 222)'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgb(0, 156, 222)',
      borderColor: 'rgb(0, 156, 222)'
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)'
    }
  }
})(Button);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(10),
    display: 'flex',
    marginBottom: theme.spacing(5)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1)
  }
}));

function DepartmentEdit(props) {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  const classes = useStyles();
  const DepartmentID = props.location.state.DepartmentID;
  const [DepartmentName, setDepartmentName] = useState('');
  const [DepartmentNameError, setDepartmentNameError] = useState(false);
  const DepartmentNameRef = useRef(null);

  const handleChange = name => event => {
    const departmentName = event.target.value;
    setDepartmentName(departmentName);
  };

  const onSubmit = name => event => {
    if (DepartmentName !== '') {
      const updates = {};
      const data = {
        DepartmentName: DepartmentName
      };
      updates['/departments/' + DepartmentID] = data;
      firebase
        .database()
        .ref()
        .update(updates);
      alert('Updated');
    } else {
      DepartmentNameRef.current.focus();
      setDepartmentNameError(true);
    }
  };

  useEffect(() => {
    let isMounted = false;
    const DepartmentRef = firebase
      .database()
      .ref('/departments/' + DepartmentID);
    DepartmentRef.on('value', snapshot => {
      const DepartmentValue = snapshot.val();
      if (!isMounted) {
        setDepartmentName(DepartmentValue.DepartmentName);
      }
    });

    return () => {
      isMounted = true;
    };
  }, [DepartmentID]);

  return (
    <div className={classes.root}>
      <HeaderUI />
      <Container maxWidth='md'>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h3'>DEPARTMENT EDIT</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            inputRef={DepartmentNameRef}
            required
            id='department-name'
            label='Department Name'
            placeholder='Department Name'
            error={DepartmentNameError}
            value={DepartmentName}
            onChange={handleChange('DepartmentName')}
            className={classes.textField}
            fullWidth
            margin='normal'
            variant='outlined'
            InputLabelProps={{
              shrink: true
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <BootstrapButton
            variant='contained'
            color='primary'
            className={classes.button}
            onClick={onSubmit('submit')}
            size='large'
          >
            Update
          </BootstrapButton>
        </Grid>
      </Container>
    </div>
  );
}

export default DepartmentEdit;
