import React, { Component } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  Col
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

class LoginBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: ''
    };
  }

  onCkick = e => {
    e.preventDefault();
    const headers = {
      username: this.state.username,
      password: this.state.password,
      application_name: 'equipment_it_av'
    };
    axios
      .post(
        'https://api-serv.phuket.psu.ac.th/api/2/passport/GetUserDetails',
        {},
        {
          headers
        }
      )
      .then(response => {
        localStorage.setItem('isAuthenticated', JSON.stringify(true));
        localStorage.setItem(
          'userInfo',
          JSON.stringify(response.data.GetUserDetailsResult.string)
        );
        this.props.history.push('/dashboard', {
          userinfo: response.data.GetUserDetailsResult.string
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  componentDidMount() {}

  render() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh'
        }}
      >
        <Col md={4}>
          <Card style={StylesCard}>
            <CardHeader style={StylesCardHeader}>PSU Passport Login</CardHeader>
            <CardBody>
              <Form name='loginBox'>
                <FormGroup>
                  <Label for='username'>Username</Label>
                  <Input
                    type='text'
                    name='username'
                    id='username'
                    placeholder='Username'
                    value={this.state.username}
                    onChange={this.handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for='password'>Password</Label>
                  <Input
                    type='password'
                    name='password'
                    id='password'
                    value={this.state.password}
                    placeholder='Password'
                    onChange={this.handleChange}
                  />
                </FormGroup>
                <Button
                  onClick={this.onCkick}
                  style={StylesButtonSubmit}
                  type='submit'
                >
                  Sign In
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </div>
    );
  }
}

export default withRouter(LoginBox);

const StylesCardHeader = {
  backgroundColor: 'rgb(0, 156, 222)',
  color: '#FFF'
};
const StylesCard = {
  borderColor: 'rgb(0, 156, 222,0.8)'
};

const StylesButtonSubmit = {
  backgroundColor: 'rgb(0, 156, 222)',
  borderColor: 'rgb(0, 156, 222,0.8)'
};
