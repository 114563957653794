import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import HeaderUI from '../HeaderUI';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import firebaseConfig from '../firebase/FirebaseConfig';
import firebase from 'firebase/app';
import 'firebase/database';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const BootstrapButton = withStyles({
  root: {
    backgroundColor: 'rgb(0, 156, 222)',
    borderColor: 'rgb(0, 156, 222)',
    '&:hover': {
      backgroundColor: 'rgb(0, 156, 222)',
      borderColor: 'rgb(0, 156, 222)'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgb(0, 156, 222)',
      borderColor: 'rgb(0, 156, 222)'
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)'
    }
  }
})(Button);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(10),
    display: 'flex'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1)
  }
}));

function EquipmentEdit(props) {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

  const classes = useStyles();
  const EquipmentID = props.location.state.EquipmentID;
  const [values, setValues] = useState({
    EquipmentName: '',
    EquipmentNumber: '',
    EquipmentDetail: '',
    EquipmentBrand: '',
    EquipmentSerialNumber: '',
    EquipmentYear: '',
    EquipmentPlace: '',
    EquipmentOwner: '',
    EquipmentBudget: ''
  });

  useEffect(() => {
    let isMounted = false;
    const EquipmentRef = firebase.database().ref('/equipments/' + EquipmentID);
    EquipmentRef.on('value', snapshot => {
      const EquipmentValue = snapshot.val();
      if (!isMounted) {
        setValues({
          EquipmentName:
            EquipmentValue.EquipmentName !== undefined
              ? EquipmentValue.EquipmentName
              : '',
          EquipmentNumber:
            EquipmentValue.EquipmentNumber !== undefined
              ? EquipmentValue.EquipmentNumber
              : '',
          EquipmentDetail:
            EquipmentValue.EquipmentDetail !== undefined
              ? EquipmentValue.EquipmentDetail
              : '',
          EquipmentBrand:
            EquipmentValue.EquipmentBrand !== undefined
              ? EquipmentValue.EquipmentBrand
              : '',
          EquipmentSerialNumber:
            EquipmentValue.EquipmentSerialNumber !== undefined
              ? EquipmentValue.EquipmentSerialNumber
              : '',
          EquipmentYear:
            EquipmentValue.EquipmentYear !== undefined
              ? EquipmentValue.EquipmentYear
              : '',
          EquipmentPlace:
            EquipmentValue.EquipmentPlace !== undefined
              ? EquipmentValue.EquipmentPlace
              : '',
          EquipmentOwner:
            EquipmentValue.EquipmentOwner !== undefined
              ? EquipmentValue.EquipmentOwner
              : '',
          EquipmentBudget:
            EquipmentValue.EquipmentBudget !== undefined
              ? EquipmentValue.EquipmentBudget
              : ''
        });
      }
    });
    return () => {
      isMounted = true;
    };
  }, [EquipmentID]);

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const onSubmit = name => event => {
    const updates = {};
    updates['/equipments/' + EquipmentID] = values;
    firebase
      .database()
      .ref()
      .update(updates);
    alert('Updated');
  };

  return (
    <div className={classes.root}>
      <HeaderUI />
      <Container maxWidth='md'>
        <form className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='h3'>EDIT EQUIPMENT</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                id='equipment-number'
                label='Equipment Number'
                placeholder='Equipment Number'
                value={values.EquipmentNumber}
                onChange={handleChange('EquipmentNumber')}
                className={classes.textField}
                fullWidth
                margin='normal'
                variant='outlined'
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                required
                id='equipment-name'
                label='Equipment Name'
                placeholder='Equipment Name'
                fullWidth
                value={values.EquipmentName}
                onChange={handleChange('EquipmentName')}
                className={classes.textField}
                margin='normal'
                variant='outlined'
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id='equipment-brand'
                label='Equipment Brand'
                placeholder='Equipment Brand'
                fullWidth
                value={values.EquipmentBrand}
                onChange={handleChange('EquipmentBrand')}
                className={classes.textField}
                margin='normal'
                variant='outlined'
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id='equipment-detail'
                label='Equipment Detail'
                placeholder='Equipment Detail'
                fullWidth
                value={values.EquipmentDetail}
                onChange={handleChange('EquipmentDetail')}
                className={classes.textField}
                multiline
                rows='4'
                margin='normal'
                variant='outlined'
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                id='equipment-serial'
                label='Equipment Serial Number'
                placeholder='Equipment Serial Number'
                fullWidth
                value={values.EquipmentSerialNumber}
                onChange={handleChange('EquipmentSerialNumber')}
                className={classes.textField}
                margin='normal'
                variant='outlined'
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id='equipment-year'
                label='Equipment Year'
                placeholder='Equipment Year'
                fullWidth
                value={values.EquipmentYear}
                onChange={handleChange('EquipmentYear')}
                className={classes.textField}
                margin='normal'
                variant='outlined'
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id='equipment-place'
                label='Equipment Place'
                placeholder='Equipment Place'
                fullWidth
                value={values.EquipmentPlace}
                onChange={handleChange('EquipmentPlace')}
                className={classes.textField}
                margin='normal'
                variant='outlined'
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id='equipment-owner'
                label='Equipment Owner'
                placeholder='Equipment Owner'
                fullWidth
                value={values.EquipmentOwner}
                onChange={handleChange('EquipmentOwner')}
                className={classes.textField}
                margin='normal'
                variant='outlined'
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id='equipment-budget'
                label='Equipment Budget'
                placeholder='Equipment Budget'
                fullWidth
                value={values.EquipmentBudget}
                onChange={handleChange('EquipmentBudget')}
                className={classes.textField}
                margin='normal'
                variant='outlined'
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <BootstrapButton
                variant='contained'
                color='primary'
                className={classes.button}
                onClick={onSubmit('submit')}
                size='large'
              >
                Update
              </BootstrapButton>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
}

export default withRouter(EquipmentEdit);
