import HeaderUI from '../HeaderUI';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import React, { useState, useEffect, useRef } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import firebase from 'firebase/app';
import 'firebase/database';
import firebaseConfig from '../firebase/FirebaseConfig';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import _ from 'lodash';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Edit from '@material-ui/icons/Edit';
import { withRouter } from 'react-router-dom';

const ColorLinearProgress = withStyles({
  colorPrimary: {
    // backgroundColor: 'rgb(0, 156, 222)'
  },
  barColorPrimary: {
    backgroundColor: 'rgb(0, 156, 222)'
  }
})(LinearProgress);

const BootstrapButton = withStyles({
  root: {
    backgroundColor: 'rgb(0, 156, 222)',
    borderColor: 'rgb(0, 156, 222)',
    '&:hover': {
      backgroundColor: 'rgb(0, 156, 222)',
      borderColor: 'rgb(0, 156, 222)'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgb(0, 156, 222)',
      borderColor: 'rgb(0, 156, 222)'
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)'
    }
  }
})(Button);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(10),
    display: 'flex',
    marginBottom: theme.spacing(5)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1)
  },
  paper: {
    marginTop: theme.spacing(5),
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 850
  }
}));

function Department(props) {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  const classes = useStyles();
  const [DepartmentName, setDepartmentName] = useState('');
  const [DepartmentNameError, setDepartmentNameError] = useState(false);
  const DepartmentNameRef = useRef(null);

  const handleChange = name => event => {
    const departmentName = event.target.value;
    setDepartmentName(departmentName);
  };
  const onSubmit = name => event => {
    if (DepartmentName !== '') {
      const dbCon = firebase.database().ref('/departments/');
      dbCon.push({
        DepartmentName: DepartmentName
      });
      setDepartmentName('');
      setDepartmentNameError(false);
    } else {
      DepartmentNameRef.current.focus();
      setDepartmentNameError(true);
    }
  };

  const [Departments, setDepartments] = useState([]);
  const [DepartmentID, setDepartmentID] = useState('');
  const [Loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  useEffect(() => {
    let isMounted = false;
    setLoading(true);
    const DepartmentRef = firebase.database().ref('/departments/');

    DepartmentRef.on('value', snapshot => {
      if (!isMounted) {
        const DepartmentValue = snapshot.val();
        const DepartmentList = _(DepartmentValue)
          .keys()
          .map(DepartmentKey => {
            let cloned = _.clone(DepartmentValue[DepartmentKey]);
            cloned.id = DepartmentKey;
            return cloned;
          })
          .value();
        setDepartments(DepartmentList);
        setLoading(false);
      }
    });
    return () => {
      isMounted = true;
    };
  }, []);

  const onDelete = event => {
    setDepartmentID(event.currentTarget.value);
    handleClickOpen();
  };

  const onDeleteOk = event => {
    const DepartmentRef = firebase
      .database()
      .ref('/departments/' + DepartmentID);
    DepartmentRef.remove();
    handleClose();
  };

  const onEdit = event => {
    props.history.push('/department/edit', {
      DepartmentID: event.currentTarget.value
    });
  };

  return (
    <div className={classes.root}>
      <HeaderUI />
      <Container maxWidth='md'>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h3'>DEPARTMENT</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              inputRef={DepartmentNameRef}
              required
              id='department-name'
              label='Department Name'
              placeholder='Department Name'
              error={DepartmentNameError}
              value={DepartmentName}
              onChange={handleChange('DepartmentName')}
              className={classes.textField}
              fullWidth
              margin='normal'
              variant='outlined'
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <BootstrapButton
              variant='contained'
              color='primary'
              className={classes.button}
              onClick={onSubmit('submit')}
              size='large'
            >
              ADD
            </BootstrapButton>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
            <Table className={classes.table} size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Department Name</TableCell>
                  <TableCell align='center'>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Departments.map((item, i) => (
                  <TableRow key={item.id}>
                    <TableCell component='th' scope='row'>
                      {i + 1}
                    </TableCell>
                    <TableCell component='th' scope='row'>
                      {item.DepartmentName}
                    </TableCell>
                    <TableCell align='center'>
                      <Tooltip title='Delete' placement='left'>
                        <IconButton value={item.id} onClick={onDelete}>
                          <Delete />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Edit' placement='right'>
                        <IconButton value={item.id} onClick={onEdit}>
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {Loading && <ColorLinearProgress />}
          </Paper>
        </Grid>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            {'Do you want to Delete ?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color='primary'>
              No
            </Button>
            <Button onClick={onDeleteOk} color='primary'>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </div>
  );
}
export default withRouter(Department);
