import React, { Component } from 'react';
import HeaderUI from '../HeaderUI';
import Container from '@material-ui/core/Container';
//import Typography from '@material-ui/core/Typography';

export default class Equipment extends Component {
  render() {
    return (
      <div>
        <HeaderUI />
        <Container maxWidth='md'>.</Container>
      </div>
    );
  }
}
