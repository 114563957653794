import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import Router from './Router';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  fas,
  faCheckSquare,
  faCoffee
} from '@fortawesome/free-solid-svg-icons';

library.add(fas, faCheckSquare, faCoffee);

ReactDOM.render(<Router />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
