import React from 'react';
import './App.css';
import LoginForm from './components/LoginBox';

function App() {
  return (
    <div className='App'>
      <LoginForm />
    </div>
  );
}

export default App;
