const firebaseConfig = {
  apiKey: 'AIzaSyBse0ibvUW-S5hAiTWL06VbDYoxw-Hbnfk',
  authDomain: 'equipments-a3ea9.firebaseapp.com',
  databaseURL: 'https://equipments-a3ea9.firebaseio.com',
  projectId: 'equipments-a3ea9',
  storageBucket: '',
  messagingSenderId: '194364203150',
  appId: '1:194364203150:web:14f50f158db42f00'
};

export default firebaseConfig;
