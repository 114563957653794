import React, { useState, useRef } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import HeaderUI from '../HeaderUI';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import firebase from 'firebase/app';
import 'firebase/database';
import firebaseConfig from '../firebase/FirebaseConfig';
import moment from 'moment';

const BootstrapButton = withStyles({
  root: {
    backgroundColor: 'rgb(0, 156, 222)',
    borderColor: 'rgb(0, 156, 222)',
    '&:hover': {
      backgroundColor: 'rgb(0, 156, 222)',
      borderColor: 'rgb(0, 156, 222)'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgb(0, 156, 222)',
      borderColor: 'rgb(0, 156, 222)'
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)'
    }
  }
})(Button);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(10),
    display: 'flex'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  dense: {
    marginTop: theme.spacing(2)
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
}));

export default function EquipmentNew() {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

  const classes = useStyles();
  const [values, setValues] = React.useState({
    EquipmentName: '',
    EquipmentNumber: '',
    EquipmentDetail: '',
    EquipmentBrand: '',
    EquipmentSerialNumber: '',
    EquipmentYear: '',
    EquipmentPlace: '',
    EquipmentOwner: '',
    EquipmentBudget: '',
    EquipmentDepartment: 'IT'
  });

  const [NumberError, setNumberError] = useState(false);
  const [NameError, setNameError] = useState(false);
  const EquipmentNumberRef = useRef(null);
  const EquipmentNameRef = useRef(null);

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const onSubmit = name => event => {
    if (values.EquipmentNumber !== '' && values.EquipmentName !== '') {
      const dbCon = firebase.database().ref('/equipments/');
      dbCon.push({
        EquipmentNumber: values.EquipmentNumber,
        EquipmentName: values.EquipmentName,
        EquipmentDetail: values.EquipmentDetail,
        EquipmentBrand: values.EquipmentBrand,
        EquipmentSerialNumber: values.EquipmentSerialNumber,
        EquipmentYear: values.EquipmentYear,
        EquipmentPlace: values.EquipmentPlace,
        EquipmentOwner: values.EquipmentOwner,
        EquipmentBudget: values.EquipmentBudget,
        EquipmentDepartment: values.EquipmentDepartment,
        AddedDate: moment().format('DD/MM/YYYY HH:mm:ss')
      });
      setValues({
        EquipmentName: '',
        EquipmentNumber: '',
        EquipmentDetail: '',
        EquipmentBrand: '',
        EquipmentSerialNumber: '',
        EquipmentYear: '',
        EquipmentPlace: '',
        EquipmentOwner: '',
        EquipmentBudget: '',
        EquipmentDepartment: 'IT'
      });
      //alert(equipmentID.key);
      alert('Added');
    } else {
      if (values.EquipmentName === '') {
        EquipmentNameRef.current.focus();
        setNameError(true);
      } else {
        setNameError(false);
      }
      if (values.EquipmentNumber === '') {
        EquipmentNumberRef.current.focus();
        setNumberError(true);
      } else {
        setNumberError(false);
      }
    }
  };

  return (
    <div className={classes.root}>
      <HeaderUI />
      <Container maxWidth='md'>
        <form className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='h3'>NEW EQUIPMENT</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                inputRef={EquipmentNumberRef}
                required
                error={NumberError}
                id='equipment-number'
                label='Equipment Number'
                placeholder='Equipment Number'
                value={values.EquipmentNumber}
                onChange={handleChange('EquipmentNumber')}
                className={classes.textField}
                fullWidth
                margin='normal'
                variant='outlined'
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                inputRef={EquipmentNameRef}
                error={NameError}
                required
                id='equipment-name'
                label='Equipment Name'
                placeholder='Equipment Name'
                fullWidth
                value={values.EquipmentName}
                onChange={handleChange('EquipmentName')}
                className={classes.textField}
                margin='normal'
                variant='outlined'
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id='equipment-brand'
                label='Equipment Brand'
                placeholder='Equipment Brand'
                fullWidth
                value={values.EquipmentBrand}
                onChange={handleChange('EquipmentBrand')}
                className={classes.textField}
                margin='normal'
                variant='outlined'
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id='equipment-detail'
                label='Equipment Detail'
                placeholder='Equipment Detail'
                fullWidth
                value={values.EquipmentDetail}
                onChange={handleChange('EquipmentDetail')}
                className={classes.textField}
                multiline
                rows='3'
                margin='normal'
                variant='outlined'
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                id='equipment-serial'
                label='Equipment Serial Number'
                placeholder='Equipment Serial Number'
                fullWidth
                value={values.EquipmentSerialNumber}
                onChange={handleChange('EquipmentSerialNumber')}
                className={classes.textField}
                margin='normal'
                variant='outlined'
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id='equipment-year'
                label='Equipment Year'
                placeholder='Equipment Year'
                fullWidth
                value={values.EquipmentYear}
                onChange={handleChange('EquipmentYear')}
                className={classes.textField}
                margin='normal'
                variant='outlined'
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id='equipment-place'
                label='Equipment Place'
                placeholder='Equipment Place'
                fullWidth
                value={values.EquipmentPlace}
                onChange={handleChange('EquipmentPlace')}
                className={classes.textField}
                margin='normal'
                variant='outlined'
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id='equipment-owner'
                label='Equipment Owner'
                placeholder='Equipment Owner'
                fullWidth
                value={values.EquipmentOwner}
                onChange={handleChange('EquipmentOwner')}
                className={classes.textField}
                margin='normal'
                variant='outlined'
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id='equipment-budget'
                label='Equipment Budget'
                placeholder='Equipment Budget'
                fullWidth
                value={values.EquipmentBudget}
                onChange={handleChange('EquipmentBudget')}
                className={classes.textField}
                margin='normal'
                variant='outlined'
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <BootstrapButton
                variant='contained'
                color='primary'
                className={classes.button}
                onClick={onSubmit('submit')}
                size='large'
              >
                ADD
              </BootstrapButton>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
}
