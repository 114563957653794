import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import firebase from 'firebase/app';
import 'firebase/database';
import firebaseConfig from './firebase/FirebaseConfig';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import Pageview from '@material-ui/icons/Pageview';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Delete from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';

const ColorLinearProgress = withStyles({
  colorPrimary: {
    // backgroundColor: 'rgb(0, 156, 222)'
  },
  barColorPrimary: {
    backgroundColor: 'rgb(0, 156, 222)'
  }
})(LinearProgress);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(10),
    display: 'flex'
    //width: '100%'
  },
  paper: {
    marginTop: theme.spacing(5),
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 850
  },
  button: {
    margin: theme.spacing(1)
  }
}));

function EquipmentTable(props) {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

  const [Equipments, setEquipments] = useState([]);
  const [EquipmentID, setEquipmentID] = useState('');
  const [Loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  useEffect(() => {
    let isMounted = false;
    setLoading(true);
    const EquipmentRef = firebase.database().ref('/equipments/');
    EquipmentRef.on('value', snapshot => {
      //console.log(snapshot.val());
      if (!isMounted) {
        const EquipmentValue = snapshot.val();
        const EquipmentList = _(EquipmentValue)
          .keys()
          .map(EquipmentKey => {
            let cloned = _.clone(EquipmentValue[EquipmentKey]);
            cloned.id = EquipmentKey;
            return cloned;
          })
          .value();
        setEquipments(EquipmentList);
        setLoading(false);
      }
    });
    return () => {
      isMounted = true;
    };
  }, []);

  const onView = event => {
    props.history.push('/equipment/edit', {
      EquipmentID: event.currentTarget.value
    });
  };

  const onDelete = event => {
    setEquipmentID(event.currentTarget.value);
    handleClickOpen();
  };

  const onDeleteOk = event => {
    const EquipmentRef = firebase.database().ref('/equipments/' + EquipmentID);
    //alert(EquipmentID);
    EquipmentRef.remove();
    handleClose();
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth='md'>
        <Typography variant='h3'>EQUIPMENTS</Typography>
        <Paper className={classes.paper}>
          <Table className={classes.table} size='small'>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Equipment Number</TableCell>
                <TableCell align='left'>Equipment Name</TableCell>
                <TableCell align='left'>Equipment Owner</TableCell>
                <TableCell align='center'>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Equipments.map((item, i) => (
                <TableRow key={item.id}>
                  <TableCell component='th' scope='row'>
                    {i + 1}
                  </TableCell>
                  <TableCell component='th' scope='row'>
                    {item.EquipmentNumber}
                  </TableCell>
                  <TableCell align='left'>{item.EquipmentName}</TableCell>
                  <TableCell align='left'>{item.EquipmentOwner}</TableCell>
                  <TableCell align='center'>
                    <Tooltip title='View' placement='left'>
                      <IconButton value={item.id} onClick={onView}>
                        <Pageview />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title='Delete' placement='right'>
                      <IconButton value={item.id} onClick={onDelete}>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {Loading && <ColorLinearProgress />}
        </Paper>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            {'Do you want to Delete ?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color='primary'>
              No
            </Button>
            <Button onClick={onDeleteOk} color='primary'>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </div>
  );
}

export default withRouter(EquipmentTable);
