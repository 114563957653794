import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HeaderUI from '../HeaderUI';
import EquipmentTable from '../EquipmentTable';
import 'firebase/database';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginBottom: theme.spacing(5)
  }
}));

function Dashboard() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <HeaderUI />
      <EquipmentTable />
    </div>
  );
}

export default Dashboard;
